<template>
    <section class="stories-wrapper" :class="{ ja: $i18n.locale == 'ja'}">
        <div class="stories-list">
            <div class="stories-row">
                <div class="stories-cel">
                    <h3 class="content-title">{{ $t('about.stories.people.title') }}</h3>
                    <p class="content-text padding-10">{{ $t('about.stories.people.text') }}</p>
                </div>
                <div class="stories-cel">
                    <imageFrame :shadowTop="10" :shadowLeft="6" class="pic-frame suits-img" url="images/about/five-suits.jpg"/>
                </div>
            </div>
            <div class="stories-row">
                <div class="stories-cel">
                    <imageFrame :shadowTop="10" :shadowLeft="6" class="pic-frame sea-img" url="images/about/sea-gathering.jpg"/>
                </div>
                <div class="stories-cel">
                    <h3 class="content-title">{{ $t('about.stories.battelfield.title') }}</h3>
                    <p class="content-text">{{ $t('about.stories.battelfield.text') }}</p>
                </div>
            </div>
        </div>  
    </section>
</template>
<script>
import imageFrame from './../../components/shadowed-pic'
export default {
    components: {
        imageFrame
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';
.stories-wrapper {
    background: #F0F0F0;
}

.stories-list {
    position: relative;
    padding: 10vw 15vw;
}

.stories-row {
    display: flex;
    &:nth-child(1) {
        margin-bottom: 8vw;
    }

    .content-title {
        @include font-size(16px);
        font-weight: 400;
        font-size: calc(16px + 5 * ((100vw - 1280px) / 1280));
        color: #1D1F1F;
        letter-spacing: 0.15em;
        margin: 0 0 2.5em;
        text-transform: uppercase;
        font-family: $family-medium;
    }

    .content-text {
        color: #626666;
        letter-spacing: 0;
        line-height: 1.56em;
        font-family: $family-light;
        @include font-size(18px);
        font-size: calc(18px + 8 * ((100vw - 1280px) / 640));
    }
}

.ja {
    .stories-row {
        .content-title {
            font-weight: bold;
            @include font-size(22.5px);
        }
    }
}

.stories-row:first-child{
    .content-text {
        padding-right: 8vw; 
    }
}

.stories-cel {
    position: relative;
    width: 50%;

    .pic-frame {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .suits-img {
        margin-right: -2.5vw;
        margin-left: 0;
    }

    .sea-img {
        width: 38vw;
        margin-top: -2.5vw;
        margin-left: -10vw;
    }
}

@media (max-width: 992px) { 
    .stories-list {
        padding: 10vw;
    }

    .stories-row {
        flex-direction: column;

        .content-title {
            @include font-size(16px);
        }

        .content-text {
            @include font-size(18px);
        }

        .padding-10 {
            padding-bottom: 30px;
        }
    }

    .stories-cel {
        width: 100%;
        min-height: 30vh;
        .suits-img {
            width: 65vw;
            margin-left: 15vw;
        }

        .sea-img {
            width: 65vw;
            margin-left: -4vw;
            margin-top: -16vw;
        }
    }
}

@media (max-width: 576px) {
    .stories-cel {
        min-height: 20vh;
    }

    .ja {
        .stories-row {
            padding: 1vh 0;
            .content-title {
                @include font-size(19.2px);
                font-weight: bold;
            }

            .content-text {
                @include font-size(18.5px);
                padding-right: 0;
            }
        }
    }
}
</style>