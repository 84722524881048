<template>
    <section style="position:relative" class="who-we-section" :class="{ ja: $i18n.locale == 'ja'}">
      <headerOfSection :title="$t('about.whowe.title')" :isWhite="true">
        {{ $t('about.whowe.subTitle') }}
      </headerOfSection>
      <div class="content-part">
        <div class="pic-container">
          <imageFrame :shadowTop="5" :shadowLeft="6" class="pic-frame" url="images/about/who-we.jpg"/>
        </div>
        <div class="text-container">
          <h3 class="content-title">{{ $t('about.whowe.textTitle') }}</h3>
          <p class="content-text">{{ $t('about.whowe.text') }}</p>
        </div>
      </div>
    </section>
</template>
<script>
import imageFrame from './../../components/shadowed-pic';
import headerOfSection from './../../components/section-header';

export default {
    components: {
        imageFrame,
        headerOfSection
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.who-we-section {
  position: relative;
  background-color: rgb(245, 245, 245);
}

.content-part {
    padding: 10vw 15vw 0vw;
    background: #F0F0F0;
    position: relative;
    display: flex;
}


.pic-container {
    position: relative;
    width: 50%;
    top:-15vw;
    left:-7vw;
}

.text-container {
    width: 50%;
    height: auto;

    .content-title {
      @include font-size(16px);
      font-weight: 400;
      font-size: calc(16px + 5 * ((100vw - 1280px) / 1280));
      color: #1D1F1F;
      letter-spacing: 0.15em;
      margin: 0 0 2.5em;
      text-transform: uppercase;
      font-family: $family-medium;
    }

    p {
      padding-right: 7vw;
    }

    .content-text {
      color: #626666;
      letter-spacing: 0;
      line-height: 1.56em;
      font-family: $family-light;
      @include font-size(18px);
      font-size: calc(18px + 8 * ((100vw - 1280px) / 640));
    }
}

  .ja {
    .text-container {
      .content-title {
        @include font-size(22.5px);
        font-weight: bold;
      }
    }
  }

@media (max-width: 992px) {  
  
  .content-part {
    padding: 0vw 15vw 20vw;
    background: #F0F0F0;
    flex-direction: column;
  }

  .pic-container {
    width: 100%;
    margin-bottom: 10vw;
    position: relative;
    margin-left: -5vw;
    height: 60vw;
    width: 90vw;
  }
  
  .text-container {
    width: 100%;
    padding-top: 30vh;

    .content-title {
      @include font-size(16px);
    }
    
    p {
      padding-right: 2vw;
      @include font-size(18px);
    }
  }
} 

@media (max-width: 576px) { 

  .content-part {
    padding: 10vw;
  }
  
  .pic-container {
    margin-bottom: 10vw;
    position: relative;
    margin-left: 0vw;
    height: 60vw;
    width: 90vw;
  }

  .text-container {
     padding-top: 20vh;

    .content-text {
      @include font-size(18px);
    }
  }

  .ja {
    .text-container {
      .content-title {
        @include font-size(19.2px);
        font-weight: bold;
      }

      .content-text {
        @include font-size(18.5px);
        padding-right: 0;
      }
    }
  }
}
</style>