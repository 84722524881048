<template>
  <section class="locations-section">
    <div class="text-container"  :class="{ 
        shown: isBlogShown,
        ja: $i18n.locale == 'ja'
      }">
      <h2>{{ $t("about.locations.title") }}</h2>
      <p>
        {{ $t("about.locations.text") }}
      </p>
      <div class="action-container">
        <router-link class="action-link" to="/contacts">
            <span>{{ $t("about.locations.buttonText") }}</span>
            <svg
            width="44"
            height="25"
            viewBox="0 0 44 25"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z"
                fill="#fff"
                fill-rule="nonzero"
            ></path>
            </svg>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
export default {
    data() {
            return {
                windowOffsetTop: 0,
                pageHeight: 0,
                isBlogShown: false
            }
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        mounted() {
            const body = document.body, html = document.documentElement;

            this.pageHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        },
        methods: {
            handleScroll() {
                this.windowOffsetTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
            },
            getBlogTriggerOffset() {
                return this.getPers(document.querySelector(".locations-section").offsetTop);
            },
            getPers(height) { 
                return height / (this.pageHeight / 100)
            }
        },
        watch: {
            windowOffsetTop() {
                this.isBlogShown = this.getPers(this.windowOffsetTop) > this.getBlogTriggerOffset() - 5;
            }
        }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.locations-section {
  position: relative;
  height: auto;
  background-color: transparent;
  padding: 5vw 11vw 5vw 50vw;
  display: flex;
  justify-content: flex-end;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: saturate(180%) blur(24px);
  background-color: rgba(25, 26, 26, 0.82);
  padding: 4vw;
  min-width: 410px;
  min-height: 400px;
  width: 35vw; 
  height: 55vh;
  border-radius: 3px;

  @include transition(all .8s);
  @include transform(scale(.8));
  @include opacity(0);

  &.shown {
    @include transform(scale(1));
    @include opacity(1);
  }

  h2 {
    font-family: $family-bold;
    color: #DCE6E6;
    font-weight: 400;
    font-size: 1.0625rem;
    @include font-size(27px);
    line-height: 21px;
  }
  p {
      font-family: $family-regular; 
      @include font-size(19px);
      color: #DCE6E6;
      line-height: 27px;
  }
}

.text-container.ja p{
  @include font-size(17px);
  line-height: 24px;
}

.action-container {
    width: 100%;
}

.action-link {
  display: block;
  position: relative;
  white-space: nowrap;
  color: rgba(0, 0, 0, 1);
  @include font-size(24px);
  line-height: 28px;
  cursor: pointer;
  z-index: 0;
  padding: 13px 20px 13px;
  background-color: #000;
  color: #DCE6E6;
  width: 311px;
  @include transition(all 500ms);
  margin: auto;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    z-index: 1;
    background-color: #4923d8;
    @include opacity(0);
    @include transition(all 500ms);
  }

  svg {
    position: relative;
    width: 25px;
    top: -1.5px;
    margin-left: 12px;
    display: inline-block;
    z-index: 2;
    @include transition(all 500ms);

    path {
      fill: #DCE6E6;
    }
  }

  &:hover {
    color: #DCE6E6;
    width: 334px;
    &:after {
      @include opacity(1);
    }

    svg {
      margin-left: 32px;
      path {
        fill: #DCE6E6;
      }
    }
  }

  span {
    position: relative;
    display: inline-block;
    z-index: 2;
    @include font-size(24px);
    line-height: 24px;
    font-family: $family-medium;
  }
}

@media (max-width: 992px) {
    .locations-section {
        padding: 40vw 5vw 5vw 5vw;
    }
    .text-container {
        width: 90vw; 
        height: 40vh;
    }
}

@media (max-width: 576px) {
    .locations-section {
        padding: 40vw 5vw 5vw 5vw;
    }

    .text-container {
        min-width: auto;
        width: 90vw; 
        height: 40vh;
        padding: 32px;
    }

    .action-link {
        width: 100%;

        &:hover {
            width: 100%;
        }

        span {
            @include font-size(16px);
        }

        svg {
            margin-left: 32px;
        }
    }
}

</style>
