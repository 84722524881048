<template>
  <div class="wrrapper">
    <cookies-popup />
    <navigation :isWhite="isWhiteNav"></navigation>
    <div class="content-page-wrapper">
        <headerSection id="company-header-section"/>
        <whoWeAreSection id="company-who-we-section"/>
        <actionsSection id="company-action-section" />
        <storiesSection id="company-stories-section"/>
        <actionLink id="company-action-link-section" class="action-about-section" :questionText="$t('actionQuestion')" :linkText="$t('actionBtnText')" linkHref="/services" />
        <locations id="company-locations-section" />
        <footerSection />
    </div>

    <div :style="{
        'filter': 'blur('+ blurVal +'px)',
        '-webkit-filter': 'blur('+ blurVal +'px)',
        'visibility': isHeroShown ? 'visible': 'hidden' 
      }" 
      class="image-bg gradient-overlay"  style="background-image: url(/images/about/hands.jpg);"></div>
    <div :style="{
      'visibility': isStub2Shown ? 'visible': 'hidden' 
    }" class="image-bg" style="background-image: url(/images/about/beverly-heals.jpg);"></div>
  </div>
</template>

<script>
import navigation from './../components/navigation'
import headerSection from './../sections/about/header'
import actionsSection from './../sections/about/actions'
import whoWeAreSection from './../sections/about/whowe'
import locations from './../sections/about/locations'
import storiesSection from './../sections/about/stories'
import footerSection from './../sections/footer'
import actionLink from './../components/action-block'
import cookiesPopup from './../components/cookies-popup'

export default {
    data() {
      return {
        sectionsSettings: [
          {
            domId: "company-header-section",
            isNavWhite: true,
            isHeroBgShown: true,
            isSecondBgShown: false,
            isBlureHeaderBg: true,
          },
          {
            domId: "company-who-we-section",
            isNavWhite: false,
            isHeroBgShown: false,
            isSecondBgShown: false,
            isBlureHeaderBg: false,
          },
          {
            domId: "company-action-section",
            isNavWhite: true,
            isHeroBgShown: false,
            isSecondBgShown: false,
            isBlureHeaderBg: false,
          },
          {
            domId: "company-stories-section",
            isNavWhite: false,
            isHeroBgShown: false,
            isSecondBgShown: true,
            isBlureHeaderBg: false,
          },
          {
            domId: "company-action-link-section",
            isNavWhite: true,
            isHeroBgShown: false,
            isSecondBgShown: true,
            isBlureHeaderBg: false,
          },
          {
            domId: "company-locations-section",
            isNavWhite: false,
            isHeroBgShown: false,
            isSecondBgShown: true,
            isBlureHeaderBg: false,
          }
        ],
        isWhiteNav: true,
        navElement: null,

        isHeroShown: true,
        isStub2Shown: false,

        mobileWidth: 793,
        tabletWidth: 992,

        blurVal: 0
      }
    },
    metaInfo: {
        title: 'D&D Promotion - Company'
    },
    components: {
       navigation, 
       headerSection, 
       actionsSection, 
       whoWeAreSection, 
       locations,
       storiesSection, 
       actionLink,
       footerSection,
       cookiesPopup
    },
    created () {
      window.addEventListener('scroll', this.companyScrollOverHandler);
    },
    destroyed () {
      window.removeEventListener('scroll', this.companyScrollOverHandler);
    },
    mounted() {
      this.navElement = document.querySelector(".nav");
      if(this.isMobile() || this.isTablet()) {
        this.isWhiteNav = true;
      }
    },
    methods: {
      companyScrollOverHandler() {
        const windowTop = this.getWindowTop();
        
        for(let i = 0; i < this.sectionsSettings.length; i++) {

          const section = this.sectionsSettings[i];
          const el = document.getElementById(section.domId);

          if(el.offsetTop <= windowTop
              && (el.offsetTop + el.offsetHeight) >= windowTop) {

              this.isHeroShown = section.isHeroBgShown;
              this.isStub2Shown = section.isSecondBgShown;
              this.isWhiteNav = section.isNavWhite;

              if(section.isBlureHeaderBg) {
                this.blurVal = windowTop / 50;
              }
          }
        }

        if(this.isMobile() || this.isTablet()) {
           this.isWhiteNav = true;
        }
      },
      isMobile() {
        return this.getWindowWidth() <= this.mobileWidth;
      },
      isTablet() {
        return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
      },
      getWindowWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      },
      getWindowTop() {
        return window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
      }
    }
}
</script>
<style lang="scss" scoped>
.action-about-section {
  position: relative;
}

.gradient-overlay {
  &::after {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4C3ED9;
    background: -moz-linear-gradient(60deg, #F5BB44, #DE645E, transparent 70%); 
    background: -webkit-linear-gradient(60deg, #F5BB44, #DE645E, transparent 70%); 
    background: linear-gradient(60deg, #F5BB44, #DE645E, transparent 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F5BB44', endColorstr='#DE645E',GradientType=0 );
  }
}

</style>
