<template>
  <section class="actions-section-wrapper">
    <h2 :class="{ ja: $i18n.locale == 'ja' }">
      <span class="word">
        <span
          class="char"
          v-for="(val, key) in $t('about.actions.rowFirst')"
          v-bind:key="key"
          v-html="val"
        ></span>
      </span>
      <span class="word">
        <span
          class="char"
          v-for="(val, key) in $t('about.actions.rowSecond')"
          v-bind:key="key"
          v-html="val"
        ></span>
      </span>
      <span class="word">
        <span
          class="char"
          v-for="(val, key) in $t('about.actions.rowThird')"
          v-bind:key="key"
          v-html="val"
        ></span>
      </span>
    </h2>
  </section>
</template>
<script>
export default {
  data() {
    return {
      mobileWidth: 793,
      tabletWidth: 992,
      colors: [
        "rgb(246, 189, 37)",
        "rgb(244, 183, 40)",
        "rgb(242, 176, 43)",
        "rgb(239, 161, 55)",
        "rgb(235, 149, 64)",
        "rgb(231, 133, 73)",
        "rgb(227, 117, 83)",
        "rgb(222, 100, 94)",
        "rgb(218, 84, 104)",
        "rgb(214, 68, 114)",
        "rgb(197, 63, 127)",
        "rgb(180, 59, 139)",
        "rgb(162, 55, 152)",
        "rgb(144, 51, 165)",
        "rgb(109, 43, 190)",
        "rgb(73, 35, 216)",
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.wordCollor);
  },
  destroyed() {
    window.removeEventListener("scroll", this.wordCollor);
  },
  methods: {
    wordCollor() {
      const section = document.getElementById("company-action-section");
      const windowTop =
        window.pageXOffset === undefined
          ? document.documentElement.scrollTop
          : window.pageYOffset;

      if (
        section.offsetTop - window.innerHeight <= windowTop &&
        section.offsetTop + window.innerHeight >= windowTop
      ) {
        const chars = document.getElementsByClassName("char");

        for (let i = 0; i < chars.length; i++) {
          this.updateCharColor(chars[i]);
        }
      }
    },
    updateCharColor(el) {
      let color = "";

      const windowTop =
        window.pageXOffset === undefined
          ? document.documentElement.scrollTop
          : window.pageYOffset;

      const height = document.getElementById("company-action-section")
        .offsetHeight;

      let offsetTop = (el.offsetTop - window.pageXOffset) + height;

      if (this.isMobile() || this.isTablet()) {
        offsetTop = el.offsetTop + window.innerHeight;
      }

      const calc = (colorNumber) => {
        return offsetTop + (60 + this.getIndex(el)) * colorNumber;
      };

      for (let i = 0; i < this.colors.length; i++) {
        if (calc(i + 1) <= windowTop) {
          color = this.colors[i];
        }
      }

      if (el.style.color != color) {
        el.style.color = color;
      }
    },
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
    getIndex(el) {
      if (!el) return -1;

      let i = 0;

      while (el) {
        i++;
        el = el.previousElementSibling;
      }

      return i;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../scss/app";

.actions-section-wrapper {
  padding: 10vw;
  background: #1d1f1f;
  position: relative;
  line-height: 150px;

  h2 {
    font-size: 9vw;
    letter-spacing: -0.01em;
    font-weight: 600;
  }

  .word {
    display: block;
    $duration: 0.2s;
    $charCoutn: 19;
    span {
      color: rgb(25, 26, 26);
      @include transition(all $duration);
      @include animation-fill-mode(both);
      font-family: $family-bold !important;
    }
  }
}
@media (max-width: 992px) {
  .actions-section-wrapper {
    line-height: 90px;

    h2 {
      font-size: 12vw;
    }
  }
}

@media (max-width: 576px) {
  .actions-section-wrapper {
    padding: 10vw 5vw;
    height: 40vh;
    display: flex;
    align-items: center;
    h2 {
      font-size: 11vw;
      line-height: 1.6em;
    }
  }
}
</style>
